import React, { useState } from "react";
import { ethers } from "ethers";

const WalletConnect = () => {
  const [walletAddress, setWalletAddress] = useState("");
  const [status, setStatus] = useState("");

  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        const address = await signer.getAddress();
        setWalletAddress(address);
        setStatus("Wallet connected!");
      } catch (error) {
        setStatus("Error connecting wallet: " + error.message);
      }
    } else {
      setStatus("No Ethereum wallet found. Please install Metamask!");
    }
  };

  const verifyWallet = async () => {
    if (!walletAddress) return setStatus("Connect your wallet first!");
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const message = "Join Telegram Channel Verification";
      const signature = await signer.signMessage(message);
      const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

      const response = await fetch(BACKEND_URL + "/verify", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ walletAddress, signature, message }),
      });

      const data = await response.json();
      console.log("debug->data", data);
      if (response.ok) {
        setStatus("Verification successful!");
      } else {
        setStatus("Verification failed: " + data.message);
      }
    } catch (error) {
      setStatus("Error verifying wallet: " + error.message);
    }
  };

  return (
    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
      <h1 style={{marginBottom: "-40px"}}>The Unfair Edge</h1>
      <p style={{padding: "30px"}}>Wallet Verification</p>
      <button onClick={connectWallet} style={{width: "80%", maxWidth: "500px", height: "40px", margin: "10px", borderRadius: "8px" }}>Connect Wallet</button>
      {walletAddress && <p>Connected Address: {walletAddress}</p>}
      <button onClick={verifyWallet} style={{width: "80%", maxWidth: "500px", height: "40px", margin: "10px", borderRadius: "8px" }}>Verify Wallet</button>
      <p>{status}</p>
      <a href="https://t.me/TheUnfairBot" className="footer-link">Start your registration at https://t.me/TheUnfairBot</a>
    </div>
  );
};

export default WalletConnect;
